$( document ).ready(function() {


    // On vehicle hover, change image plus show text
    $(".rollover-item").hover(function(){
        $(this).find('img').attr('src', $(this).data("rollover"));
    }, function(){
        $(this).find('img').attr('src', $(this).data("src"));
    });




});